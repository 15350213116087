import { website } from '@/axios'
export const countriesLink = 'admin/countries'

export default {
  getAll () {
    return website().get('countries')
  },
  getDetails (id) {
    return website().get(`${countriesLink}/${id}`)
  },
  add (payload) {
    return website().post(countriesLink, payload)
  },
  update (id, payload) {
    return website().patch(`${countriesLink}/${id}`, payload)
  }
}
